/* Hero Container */
.hero-container {
  position: relative;
  overflow: hidden;
}

/* Background Video */
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Main Image */
.main-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Adjust opacity for transparency */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition effects */
}

.main-image:hover {
  opacity: 1; /* Increase opacity on hover */
  transform: scale(1.05); /* Scale up image on hover */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth hover transition */
}

/* Content Overlay */
.hero-content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.content-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.text-container {
  max-width: 800px;
  color: #fff;
  text-align: center;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  animation: fadeInDown 1s ease-out;
}

.hero-description {
  font-size: 1.5rem;
  line-height: 2;
  margin-bottom: 30px;
  animation: fadeInUp 1s ease-out;
}

.button-container {
  display: flex;
  justify-content: center;
  animation: fadeIn 1s ease-out;
}

.hero-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #51087E;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-button:hover {
  background-color: #6B1FA7;
  transform: scale(1.05);
}

.get-started-button:hover {
  background-color: #0B875B; /* Greenish variant for Get Started button */
}

.watch-demo-button:hover {
  background-color: #E11D48; /* Reddish variant for Watch Demo button */
}

/* Video Modal */
.video-modal {
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-modal-content {
  position: relative;
  width: 80%;
  max-width: 800px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  z-index: 1;
  animation: fadeIn 1s ease-out;
}

.modal-video {
  width: 100%;
  height: auto;
  animation: zoomIn 0.5s ease-out;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
